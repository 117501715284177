import React, { Component } from "react"
import PageWrapper from "../components/PageWrapper/PageWrapper"
import SEO from "../components/seo"
import Vichy from "../components/Pages/Projects/Vichy/Vichy"

class PlanetaPage extends Component {
  render() {
    return (
      <PageWrapper>
        <SEO
          title='Vichy'
          description='A landing to promote revolutionary corrective foundation by Vichy Dermablend. Product details, tutorials and experts recommendations were brought up to introduce the product in its full.'
        />
        <Vichy/>
      </PageWrapper>
    )
  }
}

export default PlanetaPage
