import * as React from "react"
import Header from "../../../Header/Header"
import ProjectBlock from "../../../UI/ProjectBlock/ProjectBlock"
import ProjectFooter from "../../../UI/ProjectFooter/ProjectFooter"
import ProjectGoals from "../../../UI/ProjectGoals/ProjectGoals"
import ProjectIntro from "../../../UI/ProjectIntro/ProjectIntro"

import "./Vichy.sass"

const challenges = [
  "Outstanding design that extends brand identity to the web",
  "Interactive comparison of the benefits of using the product",
  "Bright and beautiful visuals to engage the audience",
  "Progressive content loading for the best mobile experience",
  "Unique content editing capabilities",
]

const solutions = [
  "Admin panel to fill the content ;",
  "Unique design;",
  "Mobile device support and cross browser compatibility;",
  "Short-term project delivery.",
]

class Vichy extends React.Component {

  componentDidMount(): void {
    this.initFaceSlider()
  }

  initFaceSlider = () => {
    var holder = document.querySelector(".vichy__results-wrapper")
    const left = holder.querySelector(".half.left") as HTMLElement;
    var sharedText = holder.querySelector('.slide_text_shared') as HTMLElement;

    var windowW = window.innerWidth
    window.addEventListener("resize", function() {
      windowW = window.innerWidth
    })

    var updateHalf = function(e) {
      if (e.clientX > windowW / 2 - 300 && e.clientX < windowW / 2 + 300) {
        left.style.transition = 'none';
        left.style.width = (e.clientX - windowW / 2 + holder.clientWidth / 2) / holder.clientWidth * 100 + '%';
        sharedText.style.transition = 'none';
        // console.log("%c  e.clientX - windowW / 2", "background: rgba(255, 255, 0, 0.3)",  e.clientX, windowW / 2, e.clientX - windowW / 2);
        sharedText.style.marginLeft = e.clientX - windowW / 2 + 'px';
      } else {
        resetHalf()
      }
    }
    var resetHalf = function() {
      left.style.transition = '';
      left.style.width = '';
      sharedText.style.transition = '';
      sharedText.style.marginLeft = '';
    }

    holder.addEventListener("mouseenter", function() {
      holder.addEventListener("mousemove", updateHalf)
    })
    holder.addEventListener("mouseleave", function() {
      holder.removeEventListener("mousemove", updateHalf)
      resetHalf()
    })
  }

  render() {

    const info = [
      { label: "Client", value: "Vichy" },
      { label: "Partnership with", value: "HOSHVA" },
      { label: "Year", value: "2015" },
      { label: "Website", value: "http://vichy.youshido.com/index.html", isLink: true, linkLabel: 'Vichy' },
    ];

    return (
      <main className='vichy'>
        <Header/>
        <ProjectIntro title='VICHY'
                      description='A landing to promote revolutionary corrective foundation by Vichy Dermablend. Product details, tutorials and experts recommendations were brought up to introduce the product in its full.'
                      heading='heading-case'
                      info={info}>
          <img src='../../../../images/projects/vichy/tube-on-texture.png' alt=''/>
        </ProjectIntro>
        <ProjectGoals challenges={challenges} solutions={solutions}/>
        <ProjectBlock className='vichy__stories'
                      heading='heading-num1'
                      title='Product Stories'
                      overlappedHeading
                      subtitle='In order to demonstrate the product in action, the landing page leads user through 3 live changes stories that serve as great examples of the product effectiveness.'
                      isImageFirst={true}>
          <img src='../../../../images/projects/vichy/imac.png' alt='Photo: iMac with dancing ballerina'/>
          <video width="100%" height="100%" loop autoPlay playsInline muted>
            <source src="../../../../images/projects/vichy/ballerina.mp4" type="video/mp4"/>
          </video>
        </ProjectBlock>
        <ProjectBlock className='vichy__results max-width'
                      heading='heading-num2'
                      title='See The Actual Result'
                      subtitle='Showing before and after has always been a great way to proof the beauty product actually works. We have developed a split screen slider that shows photos of real people with stunning improvements after using the product.'
                      headerCentered={false}
                      centered={true}>
          <img src='../../../../images/projects/vichy/dermablend-result.png' alt='Screen with photo before and after usage Vichy Dermablend'/>
          <div className="vichy__results-wrapper -desktop">
            <div className="half right"/>
            <div className="half left"/>
            <div className="slide_text">Аcne</div>
            <div className="slide_text_shared" >
              <span>Before</span> <span>After</span>
            </div>
          </div>
        </ProjectBlock>
        <ProjectBlock className='vichy__tutorials'
                      heading='heading-num3'
                      title='All Features At Glance'
                      subtitle='Product page was designed to be concise yet deliver every bit of important details about the product. Along with tutorials and guides done by professionals they gave users enough information to make an informed   decision.'
                      centered={true}
                      overlappedHeading
                      image={{
                        src: "../../../../images/projects/vichy/tutorials-screen.jpg",
                        alt: "Image: screen with video tutorials and product advantages",
                      }}/>
        <ProjectFooter title='Motivetica'
                       className='vichy__footer'
                       link='/motivetica'
                       imageSrc='../../../../images/projects/motivetica.png'
                       imageAlt='Bordered smart/clever text with authors name'
        />
      </main>
    )
  }
}

export default Vichy
